.pageBorder{
   margin: 15px; 
}

.paddingBottom, .paddingTop{
    padding-bottom: 20px;
    h5 {
        margin-bottom: 0 !important;
    }
}

.marginBottom{
    margin-bottom: 5px;
}

.logoImg{
    width: 140px;
    height: 140px;
}

.homePageContainer{
    width:50%;
    margin: 0 auto;
}

.localizacion{
    position: absolute !important;
    bottom: 80px;
    left: 35%;
    background: #333366;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.currentLocation{
    position: absolute !important;
    top: 0;
    left: 35%;
    background: #333366;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.backgroundColorButton{
    background: #333366;
    padding: 8px !important;
}
@media screen and (max-width: 480px) {
    .homePageContainer{
        width:100%;
        margin: 0 auto;
    }
    .localizacion{
        left: 12%;
        font-size: 14px;
    }
    .currentLocation{
        left: 12%;
        font-size: 14px;
    }
}
